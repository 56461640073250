import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { HiMenuAlt4 } from "react-icons/hi";
import logo from "../images/logo1.png";

export default function Nav() {
  const [move, setMove] = useState(false);
  function handleOpen() {
    setMove(true);
  }
  function handleClose() {
    setMove(false);
  }
  return (
    <>
      <motion.div
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          delay: 0.2,
          duration: 0.75,
        }}
        className="nav"
      >
        <h3>
          <img src={logo} alt=" " width={90} height={50} />
        </h3>

        <ul className="nav-content">
          <li>
            <Link to="/explore">
              <div className="faq-btn">Faq</div>
            </Link>
          </li>
          <li>
            <Link to="/explore">
              <div className="pricing-btn">Pricing</div>
            </Link>
          </li>
          <li>
            <Link to="/explore">
              <div className="login-btn">Login</div>
            </Link>
          </li>
          <li>
            <Link to="/explore">
              <div className="register-btn">Sign up</div>
            </Link>
          </li>
        </ul>

        {/* mobile */}
        <ul className="nav-content-mobile">
          {move && <div onClick={handleClose} className="mobile-blur"></div>}
          {move && (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 0.75,
              }}
              className="mobile-nav"
            >
              <li>
                <Link to="/explore">
                  <div className="faq-btn">Faq</div>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/explore">
                  <div className="pricing-btn">Pricing</div>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/explore">
                  <div className="login-btn">Login</div>
                </Link>
              </li>

              <li>
                <Link to="/explore">
                  <div className="register-btn">Sign up</div>
                </Link>
              </li>
            </motion.div>
          )}
          {move ? (
            <li>
              <FaTimes
                className="open"
                onClick={handleClose}
                style={{ marginRight: "0.5rem" }}
              />
            </li>
          ) : (
            <li className="menu-toggle">
              <HiMenuAlt4 className="open" onClick={handleOpen} />
            </li>
          )}
        </ul>
      </motion.div>
    </>
  );
}
